@font-face {
    font-family: 'PlusJakartaSans-Regular';
    src: local('PlusJakartaSans-Regular'), url(../assets/fonts/PlusJakartaSans-Regular.ttf) format('ttf');
}

@font-face {
    font-family: 'Syne-Regular';
    src: local('Syne-Regular'), url(../assets/fonts/Syne-Regular.ttf) format('ttf');
}

.textanimated {
    font-family: "PlusJakartaSans-Regular", sans-serif;
    color: #00397f;
    font-size: 26px;
  
    
    font-weight: 700;
    
}